import DesignTemplate from '../../components/DesignTemplate'
import { BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_1, BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_2, BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME } from '../../constants/DesignTemplateExperimentTest'
import { CategoryPageSectionType } from '../../types/TemplateConfiguration'

enum RegisteredTests {
  YOUR_EXPERIMENT_KEY = 'your_experiment_key',
  BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME_KEY = BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME,
}

type TestRegistry = {
  [testKey in RegisteredTests | string]: {
    [variationKey: string]: {
      sectionType?: CategoryPageSectionType;
      position?: number;
      component: (props: any) => JSX.Element;
      mcIds?: string[];
      locales?: string[];
    }[];
  };
}

const testRegistry: TestRegistry = {
  [RegisteredTests.YOUR_EXPERIMENT_KEY]: {},
  [RegisteredTests.BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_NAME_KEY]: {
    [BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_1]: [
      {
        sectionType: 'design template',
        position: 2,
        component: DesignTemplate,
        mcIds: ['businessCards'],
        locales: ['en-US', 'es-US', 'en-GB']

      },
    ],
    [BUSINESS_CARDS_DESIGN_TEMPLATE_EXPERIMENT_VARIATION_2]: [
      {
        sectionType: 'design template',
        position: 2,
        component: DesignTemplate,
        mcIds: ['businessCards'],
        locales: ['en-US', 'es-US', 'en-GB'],
      },
    ],
  },
}

const getRegisteredTest = (experimentKey: string, variationKey: string, metadata?: {
  newSectionPosition: number;
}) => {
  if ((Object.values(RegisteredTests) as string[]).includes(experimentKey)) {
    const testVariations = testRegistry[experimentKey][variationKey]
    if (testVariations) {
      return testVariations.map(variation => ({
        ...variation,
        position: metadata?.newSectionPosition ?? variation.position
      }))
    }
  }
}

const getSupportedLocales = (experimentKey: string, variationKey: string) => {
  if ((Object.values(RegisteredTests) as string[]).includes(experimentKey)) {
    return testRegistry[experimentKey][variationKey][0].locales?.map(locale => locale.toLowerCase())
  }
}

const getSupportedMechandisingId = (experimentKey: string, variationKey: string) => {
  if ((Object.values(RegisteredTests) as string[]).includes(experimentKey)) {
    return testRegistry[experimentKey][variationKey][0].mcIds
  }
}

export { RegisteredTests, getRegisteredTest, getSupportedLocales, getSupportedMechandisingId }
